<template>
  <div>
    <div class="fatherBox">
      <div class="headerBox">
        <div class="facility">
          <p @click="$router.go(-1)" class="stulist">设备列表</p>
          <p>/</p>
          <p>编号：{{ facilityID }}</p>
        </div>
      </div>
      <div class="headinfo" style="align-self: center;">
        <div>
          <p>设备编号：{{ facilityID }}</p>
          <p>设备地址：{{ site }}</p>
        </div>
        <div>
          <p>绑定类别：{{ Sbtype }}</p>
          <p>设备状态：{{ Sbstatus }} </p>
        </div>
        <div>
          <p>绑定时间：{{ InsertTime }}</p>
          <p>投放时间：{{ LaunchTime }}</p>
        </div>
      </div>
      <div>
        <div v-if="type == 4">
          <el-table :data="data_list" border style="width: 100%">
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="ssum" label="进校人数" align="center" v-if="type_display == 1"></el-table-column>
            <el-table-column prop="ssum" label="出校人数" align="center" v-if="type_display == 2"></el-table-column>
            <el-table-column prop="ysum" label="异常人数" align="center"></el-table-column>
            <el-table-column prop="tsopen" label="推送打开率" align="center"></el-table-column>

          </el-table>
        </div>
        <div v-if="type == 5">
          <el-table :data="data_list" border style="width: 100%">
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="ygynum" label="检测人数" align="center"></el-table-column>
            <!-- <el-table-column prop="ssum" label="检测近视率"></el-table-column> -->

          </el-table>
        </div>

        <div v-if="type == 6">
          <el-table :data="data_list" border style="width: 100%">
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="ptel_num" label="普通电话次数" align="center"></el-table-column>
            <el-table-column prop="ptel_time" label="普通电话时长" align="center"></el-table-column>
            <el-table-column prop="ytel_num" label="语音通话次数" align="center"></el-table-column>
            <el-table-column prop="ytel_time" label="语音电话时长" align="center"></el-table-column>
            <el-table-column prop="stel_num" label="视频通话次数" align="center"></el-table-column>
            <el-table-column prop="stel_time" label="视频通话时长" align="center"></el-table-column>

          </el-table>
        </div>
        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="total,prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>
      </div>
      <!-- 线图 -->
      <div class="fatherBox" style="margin-top: 10px;" v-if="type == 1 || type == 4 || type == 5 || type == 6" >
        <div class="ClassInfo_box">
          <div class="chartTitle">

            <ul>
              <li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index,item)"
                :class="currentIndex == index ? 'active' : ''" style="margin-top: 16px;">
                近{{ item }}天
              </li>
              <li>
                <el-date-picker v-model="searchData" type="daterange" size="small" :picker-options="pickerOptions"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                  value-format="timestamp">
                </el-date-picker>

                <el-button type="primary" style="padding: 9px 20px; margin-left: 10px;" @click="search">查询
                </el-button>

              </li>
            </ul>

          </div>
          <div class="chartTitle" style="margin-top:30px">
            <el-radio-group v-model="video_num" style="margin-left:30px;margin-bottom: 30px;float: left;"
              @change="authenticationClick">
              <el-radio-button :label="item.id" v-for="(item, index) in radio_list" :key="index">
                {{ item.title }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div id="chart" style="width: 1000px;height:500px; background: white"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    machineDetailscamera,
    machineDetailsPage,
    machineDetails
  } from "@/api/agentApi.js";
  import {
    machine_details_all,
    machine_details_video_view
  } from "@/api/agent_newApi.js";
  export default {
    data() {
      return {
        Nums: "", // 传值设备ID
        type: "", //  传值设备类型
        facilityID: "", // 显示设备编号
        site: "", // 设备地址
        InsertTime: "", // 绑定时间
        LaunchTime: "", // 投放时间
        data_list: [],
        currentIndex: 0, // 索引
        Series: [], // 折线图Y轴数据
        Dates: [], // 折线图X轴数据
        identifying: "", // 标识
        SchName: "", // 本地取值
        Sbtype: "", // 设备绑定类别
        Sbstatus: "", // 设备状态
        curr_page: 1, // 初始页
        page_size: 10, // 每页显示条目个数
        count_num: 0, // 总条数
        type_display: "", //判断 出校 还是入校
        dateList: ['3', '7', '30'], // // Table选项
        currentIndex: 0,
        dataType: 1,
        searchData: [], //搜索时间
        video_num: '1', //折线切换
        Series: [], // 折线图Y轴数据
        Dates: [], // 折线图X轴数据
        radio_list: [],
        legend_data: [],
        pickerOptions: {
          // 设置不能选择的日期
          onPick: ({
            maxDate,
            minDate
          }) => {
            this.choiceDate0 = minDate.getTime();
            if (maxDate) {
              this.choiceDate0 = '';
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max;
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
        },

      };
    },
    mounted() {
      this.Nums = this.$route.params.detailInfo; // 接收上页面传值设备ID facilitydetail/210/2 就是这个210
      this.type = this.$route.params.type; // 接收上页面传值设备类型 facilitydetail/210/2 就是这个2

      this.machine_details_all();
      this.git_list(this.curr_page);

    },
    methods: {
      //代理设备详情状态 绑定时间 设备名称等
      machine_details_all() {
        const data = {
          mechineID: this.Nums,
          mechineType: this.type,
        };
        machine_details_all(data).then((res) => {
            if (res.status.code == 1) {
              this.Sbtype = res.data.MechineType;
              this.facilityID = res.data.MechineName;
              this.InsertTime = res.data.InsertTime;
              this.LaunchTime = res.data.LaunchTime;
              this.Sbstatus = res.data.online;
              this.site = res.data.schoolName;
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      //获取数据
      git_list(curr_page) {
        const data = {
          mechineID: this.Nums,
          mechineType: this.type,
          limit: this.page_size,
          page: curr_page,
        };

        if (this.type == 4) {
          //获取 校园卫士 单设备详情
          machineDetailscamera(data).then((res) => {

              if (res.status.code == 1) {
                this.data_list = res.data.List
                this.type_display = res.data.type
                this.count_num = parseInt(res.data.CountNum) // 总条数
                if (res.data.type == 1) {
                  this.radio_list = [{
                    'id': 1,
                    'title': '进校人数'
                  }, {
                    'id': 2,
                    'title': '异常人数'
                  }];
                } else {
                  this.radio_list = [{
                    'id': 1,
                    'title': '出校人数'
                  }, {
                    'id': 2,
                    'title': '异常人数'
                  }];
                }

                this.line_chart();
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.type == 5 || this.type == 6) {
          machineDetailsPage(data).then((res) => {

              if (res.status.code == 1) {
                this.data_list = res.data.List
                this.type_display = res.data.type
                this.count_num = parseInt(res.data.CountNum) // 总条数
                if (this.type == 5) {
                  this.radio_list = [{
                      'id': 1,
                      'title': '检测人数'
                    },
                    // {
                    //     'id': 2,
                    //     'title': '近视率'
                    //   }
                  ];
                } else if (this.type == 6) {
                  this.radio_list = [{
                    'id': 1,
                    'title': '使用次数'
                  }, {
                    'id': 2,
                    'title': '使用时长'
                  }];
                }
                this.line_chart();
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }

      },
      //折线图
      line_chart() {
        const data = {
          mechineID: this.Nums,
          mechineType: this.type,
          dataType: this.dataType,
          startDate: (typeof Math.floor(this.searchData[0] / 1000) != 'undefined' && !isNaN(Math.floor(this
            .searchData[0] /
            1000))) ? Math.floor(this.searchData[0] /
            1000) : '', //开始时间 时间戳
          endDate: (typeof Math.floor(this.searchData[1] / 1000) != 'undefined' && !isNaN(Math.floor(this
            .searchData[0] /
            1000))) ? Math.floor(this.searchData[1] /
            1000) : '', //结束时间 时间戳

        };
        this.Dates = [];
        if (this.type == 4) {
          machine_details_video_view(data).then((res) => {
            if (res.status.code == 1) {
              this.Dates = res.data.date
              if (this.video_num == 1) {
                if (res.data.type == 1) {
                  this.legend_data = ['进校总人数'];
                  this.Series = [];
                  this.Series = [{
                      name: '进校总人数',
                      type: 'line',
                      data: res.data.jnum
                    },

                  ];
                } else if (res.data.type == 2) {
                  this.legend_data = ['出校总人数'];
                  this.Series = [];
                  this.Series = [{
                      name: '出校总人数',
                      type: 'line',
                      data: res.data.cnum
                    },

                  ];
                }
              } else {
                this.legend_data = ['异常人数'];
                this.Series = [];
                this.Series = [{
                    name: '异常人数',
                    type: 'line',
                    data: res.data.ysum
                  },
                ];
              }
              this.video_chart() // 执行Echarts
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch((err) => {
            console.log(err);
          });
        } else if (this.type == 5 || this.type == 6) {
          machineDetails(data).then((res) => {

            if (res.status.code == 1) {
              this.Dates = [];
              this.Dates = res.data.date
              if (this.type == 5) {
                this.legend_data = ['检测人数'];
                this.Series = [];
                this.Series = [{
                  name: '检测人数',
                  type: 'line',
                  data: res.data.ygynum
                }];
                this.video_chart() // 执行Echarts
              } else {

                if (this.video_num == 1) {
                  this.legend_data = ['普通电话量', '语音电话量', '视频电话量'];
                  this.Series = [];
                  this.Series = [{
                      name: '普通电话量',
                      type: 'line',
                      data: res.data.ptelnum
                    },
                    {
                      name: '语音电话量',
                      type: 'line',
                      data: res.data.ytelnum
                    }, {
                      name: '视频电话量',
                      type: 'line',
                      data: res.data.stelnum
                    },
                  ];

                } else if (this.video_num == 2) {
                  this.legend_data = ['普通电话使用时长', '语音电话量使用时长', '视频电话量使用时长'];
                  this.Series = [];
                  this.Series = [{
                      name: '普通电话使用时长',
                      type: 'line',
                      data: res.data.ptelhour
                    },
                    {
                      name: '语音电话量使用时长',
                      type: 'line',
                      data: res.data.ytelhour
                    }, {
                      name: '视频电话量使用时长',
                      type: 'line',
                      data: res.data.stelhour
                    },
                  ];
                }
                this.video_chart() // 执行Echarts
              }
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      },
      search() {
        this.currentIndex = -1;
        this.dataType = 4;
        this.line_chart();
      },
      //音视频 切换
      authenticationClick: function (select) {
        this.video_num = select;
        this.line_chart();
      },
      Atpitch(index, item) { // 折线图时间选择索引
        this.currentIndex = index

        switch (index) {
          case 0:
            this.dataType = 1;
            this.video_num = 1;
            this.do_this(3);
            this.line_chart();
            break
          case 1:
            this.dataType = 2;
            this.video_num = 1;
            this.do_this(7);
            this.line_chart();

            break
          case 2:
            this.dataType = 3;
            this.video_num = 1;
            this.do_this(30);
            this.line_chart();
            break
          default:
            this.dataType = 1;
            this.video_num = 1;
            this.do_this(3);
            this.line_chart();
        }
      },
      //选择时间
      do_this(num) {
        let new_date = new Date(); //获取系统当前时间
        let start_date = (new_date.getTime()).toFixed(0);
        let end_date = (new Date(new_date.getTime() - num * 24 * 60 * 60 * 1000).getTime()).toFixed(0);
      },
      //分页
      handleVideoChange(val) {
        this.curr_page = val;
        this.git_list(val);
      },
      //折线图
      video_chart() {
        const that = this

        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {
          tooltip: {
            trigger: 'axis'
          },

          legend: {
            data: this.legend_data
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },

    },
  };

</script>

<style lang="scss" scoped>
  .fatherBox {
    width: 98%;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      margin-left: 25px;
      border-bottom: 2px solid #e9e9e9;

      .facility {
        display: flex;

        p {
          color: burlywood;
        }

        p:nth-child(3) {
          color: black;
        }
      }

      .headerinfos {
        display: flex;

        p {
          color: burlywood;
        }

        p:nth-child(5) {
          color: black;
        }

        p:nth-child(3) {
          cursor: pointer;
        }
      }

      p {
        font-size: 16px;
        font-family: 萍方-简;
        margin-left: 10px;
      }

      .stulist {
        color: #8c8c8c;
        cursor: pointer;
        // margin: 0 3px 0 32px;
      }
    }

    .headinfo {
      width: 745px;
      display: flex;
      margin-left: 100px;

      div {
        width: 373px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      //   border-bottom:1px solid #cccccc ;
      .head_dates {
        display: inherit;
        margin-left: 102px;
        font-weight: bold;
      }

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890ff;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 54px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer;
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890ff;
    font-size: 14px;
  }

</style>
